import React from "react"
import ReactWOW from "react-wow"
import bannerImg from "../../assets/images/hyfen/framework-banner.png"

const IntangiblesBanner = () => {
  return (
    <div className="framework-pg-banner pg-banner">
      <div className="container">
        <div className="row framework-pg-banner-content">
          <ReactWOW delay=".1s" animation="fadeInLeft">
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
              <div className="intangibles">
                <img src={bannerImg} alt="bannerImg" />
              </div>
            </div>
          </ReactWOW>

          <ReactWOW delay=".1s" animation="fadeInRight">
            <div className="col-12 col-md-6">
              <h1 className="heading-1 pb-2">
                The Hyfen Eight<sup className="registered">&reg;</sup>
              </h1>

              <p className="text-1 pb-4">The new predictive science of value</p>

              <p className="text-5">
                We’ve determined 8 dimensions of intangible assets that predict
                business valuation, innovation, growth and the long-term
                relevance of your organisation.
              </p>

              <p className="text-5">
                Every organisation is unique. So are its intangibles. The Hyfen
                Eight<sup className="registered">&reg;</sup> gets you clear on
                your unique set of intangible assets, their value, and where the
                gaps lie. It empowers you to scale every dimension of your
                intangibles, to predict future business performance, resilience
                and strategic success.
              </p>
            </div>
          </ReactWOW>
        </div>
      </div>
    </div>
  )
}

export default IntangiblesBanner
